import React, { useEffect, useState } from "react";
import "./backgroundAnimation.css";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";
import SendWaForm from "./SendWaForm";
// import SendWaHeader from "./SendWaHeader";
import HistoryChat from "./HistoryChat";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { toast } from "react-toastify";

function SendWa() {
  const [number, setNumber] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      @keyframes gradientAnimation {
        0% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0% 50%; }
      }

      .animated-background {
        background: url(${bgImage});
        background-size: cover;
        background-position: center;
        position: relative;
        z-index: 0;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(270deg, rgba(232,161,166, 0.3), rgba(242,187,176, 0.3), rgba(196,156,192, 0.3), rgba(166,204,190, 0.3), rgba(150,185,219, 0.3));
        background-size: 400% 400%;
        animation: gradientAnimation 15s ease infinite;
        z-index: 1;
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  // Define the renderContent function before using it
  const renderContent = () => {
    if (window.location.pathname === "/history-chat") {
      // Updated to use window.location
      return <HistoryChat />;
    } else {
      return (
        <SendWaForm
          number={number}
          text={text}
          setNumber={setNumber}
          setText={setText}
          handleSubmit={handleSubmit}
        />
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");

    // Using toast.promise to handle message sending
    toast.promise(
      fetch("https://beawa.vercel.app/api/users/send-message", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          number,
          text,
        }),
      }).then((response) => {
        if (!response.ok) {
          throw new Error("Gagal mengirim pesan.");
        }
        return response.json();
      }),
      {
        pending: "Mengirim pesan...",
        success: "Pesan berhasil dikirim!",
        error: "Gagal mengirim pesan. Silakan coba lagi.",
      }
    );

    // Reset input after sending
    setNumber("");
    setText("");
  };

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%" zIndex="10">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://daak.amikom.ac.id",
            label: "DAAK",
            color: "secondary",
          }}
          dark
        />
      </MKBox>

      <MKBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        className="animated-background"
        sx={{
          paddingTop: "5rem",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div className="overlay" />

        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "100vh", zIndex: "1" }}
        >
          <Grid
            item
            xs={12}
            sm={10}
            md={7}
            lg={6}
            xl={4}
            ml={{ xs: "auto", lg: 6 }}
            mr={{ xs: "auto", lg: 6 }}
          >
            <MKBox
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 20, sm: 18, md: 20 }}
              mb={{ xs: 20, sm: 18, md: 20 }}
              mx={3}
              py={6}
              sx={{
                transition: "transform 0.3s ease",
                "&:hover": { transform: "translateY(-10px)" },
              }}
            >
              {/* Conditional content rendering */}
              {renderContent()}
            </MKBox>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default SendWa;

import axios from "axios";

const API_URL = "https://beawa.vercel.app/api"; // Ganti dengan URL backend Anda

// Fungsi untuk login user
export const loginUser = async (username, password) => {
  const response = await axios.post(`${API_URL}/users/login`, {
    username,
    password,
  });
  return response; // Hapus try/catch jika tidak ada logika tambahan
};

import React, { useState, useEffect } from "react";
import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import styles for toast

const HistoryChat = () => {
  const [chatHistory, setChatHistory] = useState([]);
  const [error, setError] = useState(null); // Mempertahankan penggunaan error

  useEffect(() => {
    const fetchChatHistory = async () => {
      const token = localStorage.getItem("token"); // Mengambil token JWT dari localStorage

      if (!token) {
        setError("Unauthorized");
        return;
      }

      try {
        const response = await fetch("https://beawa.vercel.app/api/users/history-chat", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Mengirim token JWT di header
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();

        if (result.success) {
          setChatHistory(result.data);
        } else {
          throw new Error("Failed to fetch chat history.");
        }
      } catch (error) {
        setError("Error fetching chat history.");
      }
    };

    toast.promise(fetchChatHistory(), {
      pending: "Fetching chat history...",
      success: "Chat history fetched successfully!",
      error: "Failed to fetch chat history. Please try again.",
    });
  }, []);

  if (error) {
    return (
      <MKTypography variant="body2" color="error">
        {error}
      </MKTypography>
    );
  }

  return (
    <Grid container item xs={12}>
      <MKTypography variant="h5" color="primary">
        Riwayat Pesan
      </MKTypography>
      {chatHistory.length === 0 ? (
        <MKTypography variant="body2" color="text">
          Tidak ada riwayat pesan
        </MKTypography>
      ) : (
        chatHistory.map((chat, index) => (
          <MKTypography key={chat.id} variant="body2" color="text">
            {index + 1}. {chat.number ? chat.number : "No Number"} - {chat.text} ({chat.status}) -{" "}
            {new Date(chat.created_at).toLocaleString()}
          </MKTypography>
        ))
      )}
    </Grid>
  );
};

export default HistoryChat;

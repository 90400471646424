import React from "react";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";

const SendWaHeader = () => {
  return (
    <Grid
      container
      item
      justifyContent="center"
      xs={10}
      lg={7}
      minWidth="100%"
      mx="auto"
      textAlign="center"
    >
      <MKTypography variant="h3" mb={1} color="primary">
        Send WhatsApp Text
      </MKTypography>
      {/* <MKTypography variant="h6" color="text">
        Formating pesan bisa menggunakan format WhatsApp
      </MKTypography> */}
    </Grid>
  );
};

export default SendWaHeader;

import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
// import Profile from "pages/LandingPages/Author/sections/Profile";
import Contact from "pages/LandingPages/Author/sections/Contact";
// import Footer from "pages/LandingPages/Author/sections/Footer";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/city-profile.jpg";

function Dashboard() {
  // const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  // const [name, setName] = useState("");
  // const handleRegister = async (e) => {
  //   e.preventDefault();

  //   const token = localStorage.getItem("token");

  //   // Using toast.promise to handle message sending
  //   toast.promise(
  //     fetch("https://beawa.vercel.app/api/users/register", {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         username,
  //         password,
  //         name,
  //       }),
  //     }).then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Gagal mengirim pesan.");
  //       }
  //       return response.json();
  //     }),
  //     {
  //       pending: "mengirim data...",
  //       success: "Akun berhasil dikirim!",
  //       error: "Gagal menambah user.",
  //     }
  //   );

  //   // Reset input after sending
  //   setUsername("");
  //   setPassword("");
  //   setName("");
  // };
  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%" zIndex="10">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://daak.amikom.ac.id",
            label: "DAAK",
            color: "secondary",
          }}
          dark
        />
      </MKBox>
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Contact />
          {/* <Profile /> */}
        </Card>
        {/* <Footer /> */}
      </MKBox>
    </>
  );
}

export default Dashboard;

import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import SendIcon from "@mui/icons-material/Send";
import SendWaHeader from "./SendWaHeader";

// import MKTypography from "components/MKTypography";

const SendWaForm = ({ number, text, setNumber, setText, handleSubmit }) => {
  return (
    <>
      <SendWaHeader />
      <Grid
        container
        item
        justifyContent="center"
        xs={10}
        lg={7}
        minWidth="100%"
        mx="auto"
        textAlign="center"
      >
        <MKTypography variant="h6" color="text">
          Formating pesan bisa menggunakan format WhatsApp
        </MKTypography>
      </Grid>
      <Grid
        container
        shadow="lg"
        display="flex"
        item
        xs={12}
        lg={7}
        sx={{ mx: "auto", minWidth: "100%", width: "100%" }}
      >
        <MKBox
          fullWidth
          component="form"
          method="post"
          autoComplete="off"
          onSubmit={handleSubmit}
          sx={{ maxWidth: "600px", width: "100%", mx: "auto" }} // Set a max width for the form
        >
          <MKBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <MKInput
                  variant="standard"
                  label="Nomor WhatsApp"
                  fullWidth
                  placeholder="6287123456789"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)} // Mengelola input nomor WhatsApp
                  sx={{
                    "& .MuiInput-underline:before": { borderBottomColor: "#6a11cb" },
                    "&:hover .MuiInput-underline:before": { borderBottomColor: "#2575fc" },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <MKInput
                  variant="standard"
                  label="Isi Pesan"
                  multiline
                  fullWidth
                  rows={6}
                  placeholder={`Contoh: \nKepada \n*Nama Mahasiswa* \n*NIM*\n*nb:*\n-_Pesan ini dikirim oleh sistem_`}
                  value={text}
                  onChange={(e) => setText(e.target.value)} // Mengelola input pesan
                  sx={{
                    "& .MuiInput-underline:before": { borderBottomColor: "#6a11cb" },
                    "&:hover .MuiInput-underline:before": { borderBottomColor: "#2575fc" },
                  }}
                />
              </Grid>
            </Grid>

            {/* Button Section */}
            <Grid container item justifyContent="center" xs={12} my={2}>
              <MKButton
                type="submit"
                variant="gradient"
                color="dark"
                fullWidth
                sx={{
                  background: "linear-gradient(to right, #6a11cb, #2575fc)",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#2575fc",
                    transform: "scale(1.05)",
                  },
                }}
                startIcon={<SendIcon />}
              >
                Kirim Pesan
              </MKButton>
            </Grid>
          </MKBox>
        </MKBox>
      </Grid>
    </>
  );
};

SendWaForm.propTypes = {
  number: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  setNumber: PropTypes.func.isRequired,
  setText: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default SendWaForm;

// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
// import GitHubIcon from "@mui/icons-material/GitHub";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// Pages
import SendWa from "pages/SendWa";
import Home from "pages/Home";
// import Author from "layouts/pages/landing-pages/author";
// import SignIn from "layouts/pages/authentication/sign-in";

// Sections
// import PageHeaders from "layouts/sections/page-sections/page-headers";
// import Features from "layouts/sections/page-sections/featuers";
// import Navbars from "layouts/sections/navigation/navbars";
// import NavTabs from "layouts/sections/navigation/nav-tabs";
// import Pagination from "layouts/sections/navigation/pagination";
// import Inputs from "layouts/sections/input-areas/inputs";
// import Forms from "layouts/sections/input-areas/forms";
// import Alerts from "layouts/sections/attention-catchers/alerts";
// import Modals from "layouts/sections/attention-catchers/modals";
// import TooltipsPopovers from "layouts/sections/attention-catchers/tooltips-popovers";
// import Avatars from "layouts/sections/elements/avatars";
// import Badges from "layouts/sections/elements/badges";
// import BreadcrumbsEl from "layouts/sections/elements/breadcrumbs";
// import Buttons from "layouts/sections/elements/buttons";
// import Dropdowns from "layouts/sections/elements/dropdowns";
// import ProgressBars from "layouts/sections/elements/progress-bars";
// import Toggles from "layouts/sections/elements/toggles";
// import Typography from "layouts/sections/elements/typography";

const handleLogout = () => {
  // Hapus item dari storage
  localStorage.setItem("loggedIn", false);
  localStorage.removeItem("token");

  // Redirect ke halaman login
  window.location.href = "/login";
};

const routes = [
  {
    name: "home",
    icon: <Icon>home</Icon>,
    route: "/dashboard",
    component: <Home />,
  },
  {
    name: "Send WhatsApp",
    icon: <WhatsAppIcon color="primary" />,
    route: "/send-wa",
    component: <SendWa />,
  },
  {
    name: "Riwayat Chat",
    icon: <Icon>list</Icon>, // Ikon untuk logout
    route: "/history-chat", // Ini hanya untuk konsistensi, tidak digunakan
    component: null,
  },
  {
    name: "Logout",
    icon: <Icon>logout</Icon>, // Ikon untuk logout
    route: "/logout", // Ini hanya untuk konsistensi, tidak digunakan
    component: null, // Tidak ada komponen yang perlu dirender
    onClick: handleLogout,
  },
];

export default routes;

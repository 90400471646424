import { useEffect } from "react";
import { Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
import { isTokenExpired } from "./services/auth";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import SignIn from "pages/Login";
import Dashboard from "pages/Dashboard";
import SendWa from "pages/SendWa";
// import HistoryChat from "pages/HistoryChat";

import { ToastContainer, Flip } from "react-toastify"; // Import ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import CSS untuk Toastify

export default function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const isAuthenticated = () => {
    return localStorage.getItem("token") !== null;
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("token");

      if (!token || isTokenExpired(token)) {
        navigate("/login");
      }
    };

    checkTokenExpiration();
  }, [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer
        position="bottom-center"
        stacked
        closeOnClick
        transition={Flip}
        closeButton={false}
        autoClose={1000}
        toastStyle={{
          width: "280px",
          height: "64px",
          fontSize: "18px",
        }}
      />{" "}
      {/* Tambahkan ToastContainer di sini */}
      <Routes>
        <Route
          path="/dashboard"
          element={isAuthenticated() ? <Dashboard /> : <Navigate to="/login" />}
        />
        <Route
          path="/send-wa"
          element={isAuthenticated() ? <SendWa /> : <Navigate to="/login" />}
        />
        <Route
          path="/history-chat"
          element={isAuthenticated() ? <SendWa /> : <Navigate to="/login" />}
        />
        <Route path="/login" element={<SignIn />} />
        <Route path="*" element={<SignIn />} />
      </Routes>
    </ThemeProvider>
  );
}

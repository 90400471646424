// src/page/Home.js
import React from "react";
import MKTypography from "components/MKTypography"; // Sesuaikan dengan komponen yang Anda gunakan

const Home = () => {
  return (
    <div>
      <MKTypography variant="h3" fontWeight="bold">
        Home Page
      </MKTypography>
      {/* Konten lainnya untuk halaman Home */}
    </div>
  );
};

export default Home;
